

.card-img-footer {
    background-color: #F4F6F8;
    border-color: #337ab7;
    border-width: thin;
    border-style: none;
    font-size: 12px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top:0px;
    margin-top:0px;
    text-align: center;
    justify-content: center;
}

.card-img-body {
    border-width: thin;
    border-style: none !important;
    margin-top: 10px;
    box-shadow: none !important;
    background-color: #F4F6F8;
}

.card-img-main{
    border-style: none !important;
    box-shadow: 0px, 0px 0px 0px !important;
    background-color: #F4F6F8;
}

.card-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    background-color: #FFFFFF;
}

.img-button{
    background-color: #f7f6f6;
    border-color: white;
   
}

.img-button:hover {
    background-color: #f7f6f6;
    border-color: white;
}

.btn:focus, .btn:active:focus, .btn.active:focus, btn.active {
    outline: 0 none;
    background-color: white;
    border-color:white;
}
