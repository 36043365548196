.popoutButton{
    background-color: #F4F6F8;
    border: none;
    color: black;
    font-weight: bolder;
}

.popoutButton:hover{
    background-color: #F4F6F8;
    color:blue
} 
.popoutButton:active{
    background-color: #F4F6F8;
    color:blue
}
.popoutButton:focus{
    background-color: #F4F6F8;
    color:blue
}