

.k-grid-header, k-header {
    margin: 0px,0px,0px,0px !important;
    padding:0px,0px,0px,0px !important;
}

.k-grid td {
    padding: 0.0em 0.0em 0.0em 1.5em;
    margin: 0px 0px 0px !important;
    font-size: 11px;
    white-space: nowrap !important;
    overflow: truncate;
    text-overflow: ellipsis;
}

.k-grid th{
    padding: 0.6em 0.6em 0.6em 0.6em;
    margin: 5px 0px 0px !important;
    font-size: 11px;

}

.k-grid table{
    table-layout: fixed;
}
.k-grid tbody > .k-alt
{
 background-color: white;
}

.k-grid-toolbar{
    background-color: white;
}

th.k-header.active > div > div {
    color: #fff;
    background-color: #337ab7;
}

.btn-grid{
    height: 35px;
    width: 75px;
    text-align: center;
    vertical-align: center;

}

.badge-top-right {
    right: -10px;
    vertical-align: middle;
    margin-left: -10px;
    margin-top: -20px;
    font-size: 9px;
}
.badge-middle-right {
    right: -10px;
    vertical-align:bottom;
    margin-left: -10px;
    margin-top: -20px;
    font-size: 9px;
}

.btn-array {
    margin-right: 5px;
    margin-bottom: 5px;
}

.grid-embed{
    background-color: white;
}

.k-animation-container {
    z-index: 100002
  }

.embedded-grid{
    border-style: none;
}

.worklist-grid{
    border-style: none;
}
.worklist-grid .k-grid-content{
    border-style: none;
    background-color: #F4F6F8;
}
.worklist-grid .k-grid-header{
    border-style: none;
    background-color: #F4F6F8;
}
.worklist-grid .k-table-thead{
    border-style: none;
    background-color: #F4F6F8;
}
.worklist-grid .k-table-td{
    /* border-style: none!important; */
    border-bottom-color: #62BCBD;
    border-bottom-width: 2px;
}